import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import 'bootstrap/dist/js/bootstrap';
import "./stylesheets"
const feather = require('feather-icons')

require('datatables.net');
require('datatables.net-bs')
require('datatables.net-buttons-bs')
require('datatables.net-buttons/js/buttons.colVis.js')
require('datatables.net-buttons/js/buttons.html5.js')
require('datatables.net-buttons/js/buttons.print.js')
require('datatables.net-responsive-bs')
require('datatables.net-select')

window.$ = $
Turbolinks.start();
Rails.start();

$(document).on('turbolinks:load', function(){
  feather.replace();
  $(".alert" ).fadeOut(3000);

  $(".search_form").find('form').on('input', function() {
    $(this).find('button').click();
  });
});
